import React from "react"

import ContactDetails from "../containers/ContactDetails"
import Feedback from "../containers/Feedback"
import Layout from "../containers/Layout"
import PageTitle from "../containers/PageTitle"
import SEO from "../components/SEO"
import { ContentWrapper } from "../containers/elegant.style"

const FeedBackFormPage = () => {
  return (
    <>
      <SEO
        title="Feedback Page"
        shortDesc="Elegant Beauty Clinic"
        description="Contact us to book an appointment at one of our clinics in Bournemouth, Poole or Southampton. Call Shikha on 07817 594 092 or complete online form here."
      />
      <Layout>
        <ContentWrapper>
          <PageTitle title="Feedback Form" subtitle="Feedback Elegant" />
          <Feedback />
          <ContactDetails />
        </ContentWrapper>
      </Layout>
    </>
  )
}

export default FeedBackFormPage
