import * as Yup from "yup"
import Fade from "react-reveal/Fade"
import PropTypes from "prop-types"
import styled from "styled-components"
import React, { useState } from "react"
import { Field, Form, Formik, useField } from "formik"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

import Box from "../../components/Box"
import Container from "../../components/UI/Container"
import Heading from "../../components/Heading"
import Text from "../../components/Text"
import ContactWrapper, {
  CheckBoxes,
  ContactFromWrapper,
  ContentWrapper,
  StyledErrorMessage,
  StyledSelect,
} from "./feedback.style"

const MyCheckbox = ({ children, ...props }) => {
  const [field, meta] = useField({ ...props, type: "checkbox" })
  return (
    <>
      <label className="checkbox">
        <input {...field} {...props} type="checkbox" />
        {children}
      </label>
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </>
  )
}

const Feedback = ({ formTitle, landingPage }) => {
  const [msgSent, setMsgSent] = useState(false)

  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  const trackButtonEvent = () => {
    trackCustomEvent({
      category: "'Contact us",
      action: "submit",
      label: "Contact us form",
    })
  }

  return (
    <ContactWrapper landingPage={landingPage}>
      <Container width="1317px">
        <Box>
          <ContactFromWrapper>
            {!msgSent ? (
              <Fade up delay={100}>
                {/* <Heading content="Feedback Form" {...formTitle} /> */}
                <Text
                  content="To keep us providing a 5 star service please fill out the feedback form below"
                  className="sub-heading"
                />
              </Fade>
            ) : (
              <Fade up delay={100}>
                <Heading
                  content="Your feedback has been submitted succesfully"
                  {...formTitle}
                />
              </Fade>
            )}
            <Formik
              initialValues={{
                messageText: "",
              }}
              validationSchema={Yup.object({
                messageText: Yup.string().required("Required"),
              })}
              onSubmit={(values, actions) => {
                fetch("/", {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                  },
                  body: encode({ "form-name": "feedback", ...values }),
                })
                  .then(() => {
                    setMsgSent(true)
                    // alert("Success")
                    actions.resetForm()
                  })
                  .catch(() => {
                    alert("Error")
                  })
                  .finally(() => actions.setSubmitting(false))
              }}
            >
              <Form name="feedback" data-netlify={true}>
                <Fade bottom>
                  <div>
                    <label htmlFor="messageText">Your Feedback</label>
                    <Field
                      label="Your feedback?"
                      name="messageText"
                      component="textarea"
                      rows="10"
                    />
                  </div>
                </Fade>

                <Fade bottom>
                  <div>
                    <button onClick={trackButtonEvent} type="submit">
                      Submit form
                    </button>
                  </div>
                </Fade>
              </Form>
            </Formik>
          </ContactFromWrapper>
        </Box>
      </Container>
    </ContactWrapper>
  )
}

Feedback.propTypes = {
  formTitle: PropTypes.object,
}

Feedback.defaultProps = {
  // form title default style
  formTitle: {
    textAlign: "center",
    fontSize: ["28px", "40px"],
    fontWeight: "100",
    letterSpacing: "1px",
    mb: "60px",
    color: "#333333",
  },
}

export default Feedback
